import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar'

const FetchBooking = () => {
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    fetchBookings();
  }, []); // Fetch bookings when the component mounts

  const fetchBookings = () => {
    axios.get("http://localhost:3030/fetchBooking")
      .then(response => {
        setBookings(response.data);
      })
      .catch(error => {
        console.error("Error fetching bookings:", error);
      });
  }

  return (
    <div>
      <Navbar />
      <h1>Details of bookings</h1>
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking, index) => (
            <tr key={index}>
              <td>{booking.Date}</td>
              <td>{booking.Time}</td>
              <td>{booking.Name}</td>
              <td>{booking.Email}</td>
              <td>{booking.phno}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FetchBooking;
