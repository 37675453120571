import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Outlet, Link } from "react-router-dom";
import io from 'socket.io-client';
const socket = io('http://localhost:3030');

const UserMenuNav = () => {
  
  const [notificationMessage, setNotificationMessage] = useState('');
  const navigate = useNavigate();

  const handleCallWaiter = () => {
    // Emit a 'call-waiter' event to notify the restaurant
    socket.emit('call-waiter', { message: 'Please call the waiter to this table.' });
    setNotificationMessage('Waiter has been called.');
  };

  return (
    <div>
      <nav>
      <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">Qr Menu</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          {/* <a className="nav-link active" aria-current="page" href="./Home">Home</a> */}
          {/* <Link to="/UserMenu" className='nav-link active' aria-current="page">Home</Link> */}
        </li>
        {/* <li className="nav-item">
          <Link to="/TableBooking" className='nav-link active' aria-current="page">Table Booking</Link>
        </li> */}
        {/* <li className="nav-item">
          <Link to="/Contact" className='nav-link active' aria-current="page">Contact Information</Link>
        </li> */}
      </ul>
    </div>
  </div>
</nav>
      </nav>
    </div>
  )
}

export default UserMenuNav
