import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import '../user/UserMenuCss.css';
import '../user/UserMenu';

const GenerateQRCode = ({ UserMenu }) => {
  return (
    <div>
      <h2 className='qrtitle'>QR Code for Menu</h2>
      <QRCode value={UserMenu} />
    </div>
  );
};

export default GenerateQRCode;
