import React, { useEffect, useState } from "react";
import axios from "axios";
import { Navigate, useLocation } from "react-router-dom";
import Navbar from "../Navbar";


const EditMenu = () => {
  const location = useLocation();
  const id = location.state;

  const [formData, setFormData] = useState({
    itemId: "",
    itemName: "",
    itemImage: "",
    itemCategory: "",
    itemDetails: "",
    itemPrice: "",
  });

  const [base64Image, setBase64Image] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setBase64Image(base64String);
        setSelectedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    axios.get(`http://localhost:3030/fetchbymenuid/${id}`).then((res) => {
      setFormData(res.data);
    });
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    setFormData({ ...formData, itemImage: base64Image });
    e.preventDefault();
    console.log(formData)
    axios.put(`http://localhost:3030/editMenu/${id}`, formData).then((res) => {
      alert(res.data.message);
    });
  };

  return (
    <div>
      <Navbar></Navbar>
      <form onSubmit={handleSubmit}>
        <legend>Edit Menu</legend>
        <input type="text" name="itemId" placeholder="item Id" value={formData.itemId} onChange={handleInputChange} /><br />
        <input type="text" name="itemName" placeholder="Item Name" value={formData.itemName} onChange={handleInputChange} /><br />
        <input type="file" name="itemImage" onChange={handleImageUpload} /><br />
        {base64Image && <img src={base64Image} alt="Uploaded" />} <br /><br />
        <input type="text" name="itemCategory" placeholder="itemCategory" value={formData.itemCategory} onChange={handleInputChange} /><br />
        <input type="text" name="itemDetails" placeholder="itemDetails" value={formData.itemDetails} onChange={handleInputChange} /><br />
        <input type="text" name="itemPrice" placeholder="itemPrice" value={formData.itemPrice} onChange={handleInputChange} /><br />
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default EditMenu;
