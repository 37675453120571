import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Navbar from '../Restaurant Pages/Navbar';
import Menu from '../Menu';
import './Home.css';
import { Link, useNavigate } from 'react-router-dom';

const socket = io('http://localhost:3030');



const Home = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  

  useEffect(() => {
    // Listen for the 'call-waiter' event and update notifications
    socket.on('call-waiter', (data) => {
      setNotifications((prevNotifications) => [...prevNotifications, data.message]);
    });

    // Clean up the listener when unmounting
    return () => {
      socket.off('call-waiter');
    };
  }, []);
  
  return (
    <div className="main">
      <header>
        <Navbar />
      </header>

      <section className="section-header pb-7 pb-lg-11 bg-soft">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-md-6 order-2 order-lg-1">
              <img src="https://qrmenu.happygiftstore.in/impactfront/img/flayer.png" alt="" />
            </div>
            <div className="col-12 col-md-5 order-1 order-lg-2">
              <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
              <h1 className="display-2 mb-3 ckedit" key="contactless_menu" id="contactless_menu" contentEditable="false">Contact-less QR Menu</h1>
              <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
              <p className="lead ckedit" key="hero_title" id="hero_title" contentEditable="false">Create a digital menu for your Restaurant. Engage more with your customers.</p>
              <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
              <p className="lead ckedit" key="hero_subtitle" id="hero_subtitle" contentEditable="false"><strong>Their mobile is your menu now!</strong></p>
              <div className="mt-4">
                <a className="btn btn-primary d-flex justify-content-center" href="http://localhost:3000/Register" role="button">Join Now</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pattern bottom"></div>
      </section>

      {/* platform features */}

      <section id="product" className="section section-lg">
        <div className="container">
          <div className="row justify-content-center mb-5 mb-md-7">
            <div className="col-12 col-md-8 text-center">
              <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
              <h2 className="h1 font-weight-bolder mb-4 ckedit" key="product_title" id="product_title" contentEditable="false">The best platform for QR digital menu</h2>
              <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
              <p className="lead ckedit" key="product_description" id="product_description" contentEditable="false">There are platforms where you can make QR code, but no menu. There are platforms where you can create a menu but not design your QR</p>
              <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
              <p className="lead ckedit" key="product_subtitle" id="product_subtitle" contentEditable="false"><strong>We do both..</strong></p>
            </div>
          </div>

          <div className="container cardfeatures">
            <div className="row justify-content-center mb-5 mb-md-7">
              <div className="maincard col-12 col-md-6 col-lg-4 mb-5">
                <div className="card shadow-soft border-light">
                  <div className="card-header p-0">
                    <img src="https://qrmenu.happygiftstore.in/impactfront/img/localorders.jpg" className="card-img-top rounded-top" alt="image" />
                  </div>
                  <div className="card-body">
                    <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                    <h3 className="card-title mt-3 ckedit" id="product4_title" key="product4_title" contentEditable="false">Accept local orders</h3>
                    <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                    <p className="card-text ckedit" id="product4_subtitle" key="product4_subtitle" contentEditable="false">Your customers can order directly from their phone</p>
                    <ul className="list-group d-flex justify-content-center mb-4">
                      <li className="list-group-item d-flex pl-0 pb-1">
                        <span className="mr-2"><i className="fas fa-check-circle text-success mx-1"></i></span>
                        <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                        <div className="ckedit" id="product4_feature1" key="product4_feature1" contentEditable="false">Real-time sound notifications</div>
                      </li>
                      <li className="list-group-item d-flex pl-0 pb-1">
                        <span className="mr-2"><i className="fas fa-check-circle text-success mx-1"></i></span>
                        <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                        <div className="ckedit" id="product4_feature2" key="product4_feature2" contentEditable="false">Continues orders</div>
                      </li>
                      <li className="list-group-item d-flex pl-0 pb-1">
                        <span className="mr-2"><i className="fas fa-check-circle text-success mx-1"></i></span>
                        <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                        <div className="ckedit" id="product4_feature3" key="product4_feature3" contentEditable="false">Unlimited options, variants, and extras</div>
                      </li>
                    </ul>
                    <a href="#" className="btn btn-primary d-flex justify-content-center">Try it</a>
                  </div>
                </div>
              </div>

              <div className="maincard col-12 col-md-6 col-lg-4 mb-5">
                <div className="card shadow-soft border-light">
                  <div className="card-header p-0">
                    <img src="https://qrmenu.happygiftstore.in/impactfront/img/payments.jpg" className="card-img-top rounded-top" alt="image" />
                  </div>
                  <div className="card-body">
                    <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                    <h3 className="card-title mt-3 ckedit" id="product5_title" key="product5_title" contentEditable="false">Accept payments</h3>
                    <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                    <p className="card-text ckedit" id="product5_subtitle" key="product5_subtitle" contentEditable="false">Your customers can pay orders directly via card/Bank transfers.</p>
                    <ul className="list-group d-flex justify-content-center mb-4">
                      <li className="list-group-item d-flex pl-0 pb-1">
                        <span className="mr-2"><i className="fas fa-check-circle text-success mx-1"></i></span>
                        <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                        <div className="ckedit" id="product5_feature1" key="product5_feature1" contentEditable="false">Accept any Credit and Debit card</div>
                      </li>
                      <li className="list-group-item d-flex pl-0 pb-1">
                        <span className="mr-2"><i className="fas fa-check-circle text-success mx-1"></i></span>
                        <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                        <div className="ckedit" id="product5_feature2" key="product5_feature2" contentEditable="false">Get paid immediately via Razorpay</div>
                      </li>
                      <li className="list-group-item d-flex pl-0 pb-1">
                        <span className="mr-2"><i className="fas fa-check-circle text-success mx-1"></i></span>
                        <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                        <div className="ckedit" id="product5_feature3" key="product5_feature3" contentEditable="false">Secured online payments</div>
                      </li>
                    </ul>
                    <a href="#" className="btn btn-primary d-flex justify-content-center">Accept contactless payments</a>
                  </div>
                </div>
              </div>

              <div className="maincard col-12 col-md-6 col-lg-4 mb-5">
                <div className="card shadow-soft border-light">
                  <div className="card-header p-0">
                    <img src="https://qrmenu.happygiftstore.in/impactfront/img/customerlog.jpg" className="card-img-top rounded-top" alt="image" />
                  </div>
                  <div className="card-body">
                    <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                    <h3 className="card-title mt-3 ckedit" id="product6_title" key="product6_title" contentEditable="false">Customer Log</h3>
                    <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                    <p className="card-text ckedit" id="product6_subtitle" key="product6_subtitle" contentEditable="false">Countries have legal requirement to keep log of their customers.</p>
                    <ul className="list-group d-flex justify-content-center mb-4">
                      <li className="list-group-item d-flex pl-0 pb-1">
                        <span className="mr-2"><i className="fas fa-check-circle text-success mx-1"></i></span>
                        <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                        <div className="ckedit" id="product6_feature1" key="product6_feature1" contentEditable="false">Customers can register themselves</div>
                      </li>
                      <li className="list-group-item d-flex pl-0 pb-1">
                        <span className="mr-2"><i className="fas fa-check-circle text-success mx-1"></i></span>
                        <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                        <div className="ckedit" id="product6_feature2" key="product6_feature2" contentEditable="false">Manage the visits on your own</div>
                      </li>
                      <li className="list-group-item d-flex pl-0 pb-1">
                        <span className="mr-2"><i className="fas fa-check-circle text-success mx-1"></i></span>
                        <i className="fas fa-edit mr-2 text-primary ckedit_btn" type="button" style={{ display: 'none' }}></i>
                        <div className="ckedit" id="product6_feature3" key="product6_feature3" contentEditable="false">Powerful export / report functionality</div>
                      </li>
                    </ul>
                    <a href="#" className="btn btn-primary d-flex justify-content-center">Keep it safe</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      



      {/* footer section */}

      <footer className="footer section pt-6 pt-md-8 pt-lg-10 pb-3 bg-primary text-white overflow-hidden">
        {/* <div className="pattern pattern-soft top"></div> */}
        <div className="container allfootercontent">
          <div className="row">
            <div className="col-lg-3 mb-4 mb-lg-0">
              <a href="#" className="icon icon-lg text-white mr-3 ">
                <h3>My QrMenu</h3>
              </a>
              <p className="my-4">Create a digital menu for your Restaurant. Engage more with your customers.<br />Their mobile is your menu now!</p>
            </div>
            <div className="col-6 col-sm-3 col-lg-3 mb-4 mb-lg-0">
              <h6>Helpful Links</h6>
              <ul className="links-vertical">
                <li><a target="_blank" href="#" className="footerlinks">Terms and conditions</a></li>
                <li><a target="_blank" href="#" className="footerlinks">Privacy Policy</a></li>
              </ul>
            </div>

            <div className="col-6 col-sm-3 col-lg-3 mb-4 mb-lg-0">
              <h6>My Account</h6>
              <div className='d-flex'>
                <Link to="/Login" className='nav-link active btn btn-secondary px-3 py-2' aria-current="page">Login</Link>
                <Link to="/Register" className='nav-link active btn btn-secondary px-2 py-2' aria-current="page">Register</Link>
              </div>
            </div>

            
          </div>

          <hr className="my-4 my-lg-5" />
          <div className="row">
            <div className="col pb-4 mb-md-0">
              <div className="d-flex text-center justify-content-center align-items-center">
                <p className="font-weight-normal mb-0" >© <a href="#" target="_blank" className='footertitle '>My QrMenu</a>
                  <span className="current-year mx-1">2023.</span> All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
