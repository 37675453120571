import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const socket = io('http://localhost:3030'); // Replace with your server URL

function AlarmPanel() {
  const [alarmMessage, setAlarmMessage] = useState('');
  const [alarms, setAlarms] = useState([]);

  useEffect(() => {
    socket.on('new-alarm', (data) => {
      setAlarms([...alarms, data.message]);
    });

    return () => {
      socket.disconnect();
    };
  }, [alarms]);

  const handleSetAlarm = () => {
    socket.emit('set-alarm', { alarmMessage });
    setAlarmMessage('');
  };

  return (
    <div>
      <h2>Restaurant Alarm Panel</h2>
      <div>
        <input type="text" placeholder="Enter alarm message" value={alarmMessage} onChange={(e) => setAlarmMessage(e.target.value)}/>
        <button onClick={handleSetAlarm}>Set Alarm</button>
      </div>
      <div>
        <h3>Alarms:</h3>
        <ul>
          {alarms.map((alarm, index) => (
            <li key={index}>{alarm}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AlarmPanel;
