import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';

const Menu = ({ tableId }) => {
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    // Fetch menu data based on tableId
    axios.get(`http://localhost:3030/menu/${tableId}`).then((menuData) => {
      setMenu(menuData.data);
    });
  }, [tableId]);

  if (!menu) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <h1>Menu for Table {tableId}</h1>
      {/* Render menu details */}
      <div className="card" key={menu.itemId}>
            {/* Render menu details */}
            <img src={menu.itemImage} alt={menu.itemName} />
            <div className="card-body">
              <h3>{menu.itemName}</h3>
              <p>Category: {menu.itemCategory}</p>
              <p>Details: {menu.itemDetails}</p>
              <p>Price: {menu.itemPrice}</p>
            </div>
          </div>
        </div>
  );
};

export default Menu;
