import React, { Component } from 'react';
import axios from 'axios';
import Navbar from './Navbar'
import './RestaurantStyles.css'

class AddMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64Image: '',
      selectedImage: null,
    };
  }

  handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        this.setState({
          base64Image: base64String,
          selectedImage: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  handleSubmit = () => {
    const menu = {
      itemId: this.refs.itemId.value,
      itemName: this.refs.itemName.value,
      itemImage: this.state.base64Image, // Use this.state to access state variables
      itemCategory: this.refs.itemCategory.value,
      itemDetails: this.refs.itemDetails.value,
      itemPrice: this.refs.itemPrice.value,
    };

    axios.post('http://localhost:3030/addMenu', menu)
      .then((response) => {
        console.log('Registered Successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  render() {
    const { base64Image } = this.state; // Destructure state variables

    return (
      <div>
        <Navbar></Navbar>
        <div className="form-container">
        
        <div className="card menu-card">
          <h1>Add Menu</h1>
          <input type="text" ref="itemId" placeholder="Item ID" />
          <input type="text" ref="itemName" placeholder="Item Name" /> 
          <input type="file" ref="itemImage" accept="image/*" onChange={this.handleImageUpload} />
          {base64Image && <img src={base64Image} alt="Uploaded" />} 
          <input type="text" ref="itemCategory" placeholder="Item Category" />
          <input type="text" ref="itemDetails" placeholder="Item Details" />
          <input type="text" ref="itemPrice" placeholder="Item Price" />
          <button type="button" onClick={this.handleSubmit}>Submit</button>
        </div>
      </div>
      </div>
      

    );
  }
}

export default AddMenu;
