import axios from 'axios';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Navbar from './Base Panel/Navbar';
import { Navigate } from 'react-router-dom';
import LoginBG from './LoginBG.jpg'

import './LoginStyles.css'; // Import the CSS file

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      restaurantName: '',
      ownerName: '',
      address: '',
      mobileNumber: '',
      city: '',
      email: '',
      password: '',
      redirect: false,
    };
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/RestaurantHome" />;
    }

    return (
        <div>
            <Navbar></Navbar>
            <div className="login-container">

                <fieldset>
                    <legend >Login Form</legend>
                    <input type="text" ref="loginId" className='loginid' placeholder="Login ID" /><br />
                    <input type="password" ref="password" className='password' placeholder="Password" /><br />
                    <Button color="primary" onClick={this.login}>
                        Login Check
                    </Button>
                </fieldset>
            </div>
        </div>
      
    );
  }

  login = () => {
    let loginId = this.refs.loginId.value;
    let password = this.refs.password.value;

    if (loginId === 'ADMIN' && password === 'PASSWORD') {
      alert('Login Successful!!!');
      this.setState({ redirect: true });
    } else {
      axios
        .get('http://localhost:3030/login/' + loginId + '/' + password)
        .then((data) => {
          if (loginId === data.data.email) {
            this.setState(data.data);
            this.setState({ redirect: true });
          } else {
            alert('Invalid Credentials');
          }
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    }
  };
}