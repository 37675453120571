import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';


const EditTable = () => {
  const location = useLocation();
  const id = location.state;

  

  const [formData, setFormData] = useState({
    TableId: '',
    TableName: '',
    TableLocation: '',
  });

  

  useEffect(() => {
    axios.get(`http://localhost:3030/fetchbytableid/${id}`).then((res) => {
      setFormData(res.data);
    });
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`http://localhost:3030/updatetable/${id}`, formData).then((res) => {
      alert(res.data.message);
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <legend>Login</legend>
        <input type="text" name="TableId" placeholder="Table ID" value={formData.TableId} onChange={handleInputChange}/><br />
        <input type="text" name="TableName" placeholder="Table Name" value={formData.TableName} onChange={handleInputChange}/><br />
        <input type="text" name="TableLocation" placeholder="Table Location" value={formData.TableLocation} onChange={handleInputChange}/><br />
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default EditTable;