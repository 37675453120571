import React, {useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';



const FetchTables = () => {
  const [tables, settables] = useState([]);
  const navigate  = useNavigate();


  const Delete=(id)=>{
    axios.delete(`http://localhost:3030/deletetable/${id}`).then(
      res=> console.log(res.data.message)
    
    )
    alert("SUCCESSFULLY DELETED")
  }

  const edittable = (id) =>{
    navigate(`/fetchtables/edittable/${id}`,{state:id})
  }

  useEffect(() => {
    axios.get("http://localhost:3030/fetchtables").then(
      data => {
        settables(data.data)      
      }
    )
  },[]);

  return (
    
    <div>
      <Navbar></Navbar>
      <h1>Details of tables</h1>
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr><th>Id</th><th>Table Name</th><th>Table Location</th></tr>
        </thead>
        <tbody>
          {tables.map((table) => (
            <tr>
              <td>{table.TableId}</td>
              <td>{table.TableName}/</td>
              <td>{table.TableLocation}</td>
              <td onClick={()=>edittable(table.TableId)}><button>Edit</button></td>
              <button className=" btn btn-primary">
                  <td onClick={()=>Delete(table.TableId)}>Delete</td>
              </button>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  )
}

export default FetchTables