import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Outlet, Link } from "react-router-dom";

const Navbar = () => {
  
  const navigate  = useNavigate();
  return (
    <div>
      <nav>
      <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">Qr Menu</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          {/* <a className="nav-link active" aria-current="page" href="./Home">Home</a> */}
          <Link to="/RestaurantHome" className='nav-link active' aria-current="page">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/AddMenu" className='nav-link active' aria-current="page">Add Menu</Link>
        </li>
        <li className="nav-item">
          {/* <a className="nav-link active" aria-current="page" href="./Fetch">Edit Menu</a> */}
          <Link to="/Fetch" className='nav-link active' aria-current="page">Edit Menu</Link>
        </li>
        <li className="nav-item">
          {/* <a className="nav-link active" aria-current="page" href="./AddTable">Add Tables</a> */}
          <Link to="/AddTable" className='nav-link active' aria-current="page">Add Tables</Link>
        </li>
        <li className="nav-item">
          {/* <a className="nav-link active" aria-current="page" href="./FetchTables">Edit Table</a> */}
          <Link to="/FetchTables" className='nav-link active' aria-current="page">Edit Table</Link>
        </li>
        <li className="nav-item">
          {/* <a className="nav-link active" aria-current="page" href="./MenuAndTables">Menu & Tables</a> */}
          <Link to="/MenuAndTables" className='nav-link active' aria-current="page">Menu & Tables</Link>
        </li>
        <li className="nav-item">
          {/* <a className="nav-link active" aria-current="page" href="./UserMenu">User Menu</a> */}
          <Link to="/UserMenu" className='nav-link active' aria-current="page">User Menu</Link>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            English
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Espanol</a></li>
            <li><a className="dropdown-item" href="#">Francias</a></li>
          </ul>
        </li>
      </ul>
      <a class="btn btn-primary" href="/Home" onClick={()=>navigate('/RestaurantHome')} role="button">Logout</a>
    </div>
  </div>
</nav>
      </nav>
    </div>
  )
}

export default Navbar
