import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import GenerateQRCode from "./GenerateQrCode"; // Import the QR code component
import "../user/UserMenuCss.css";

const MenuAndTables = () => {
  const [menus, setMenus] = useState([]);
  const [tables, setTables] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch menus and tables data here
    axios.get("http://localhost:3030/fetch").then((menuData) => {
      setMenus(menuData.data);
    });

    axios.get("http://localhost:3030/fetchtables").then((tableData) => {
      setTables(tableData.data);
    });
  }, []);

  return (
    <div >
      <Navbar />
      <h1>Menu and Tables</h1>
      {/* <div className="menu-container">
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Category</th>
              <th>Details</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {menus.map((menu) => (
              <tr className="menutable" key={menu.itemId}>
                <td>{menu.itemId}</td>
                <td>{menu.itemName}</td>
                <td>{menu.itemCategory}</td>
                <td>
                  <img className="menuandqr" src={menu.itemImage} alt={menu.itemName}/>
                </td>
                <td>{menu.itemDetails}</td>
                <td>{menu.itemPrice}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      
      <div className="table-container">
        <div className="qr-code-container">
          {tables.map((table) => (
            <div className="card" key={table.TableId}>
              <div className="card-body">
                <h4>Table {table.TableId}</h4>
                <p>Name: {table.TableName}</p>
                <p>Location: {table.TableLocation}</p>
              </div>
              <div className="qrcode">
                <GenerateQRCode menuLink={`/UserMenu/${table.TableId}`} />
                {/* <Navbar tableId={table.TableId} /> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuAndTables;
