import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import "../user/UserMenuCss.css";

const Contact = () => {
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    axios.get("http://localhost:3030/contactInfo").then((response) => {
      setRestaurants(response.data);
    });
  }, []);

  return (
    <div>
      <Navbar />
      <h1>Menu and Tables</h1>
      <div className="menu-container">
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>Restaurant Name</th>
              <th>Owner Name</th>
              <th>Address</th>
              <th>Mobile Number</th>
              <th>City</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {restaurants.map((restaurant, index) => (
              <tr className="menutable" key={index}>
                <td>{restaurant.restaurantName}</td>
                <td>{restaurant.ownerName}</td>
                <td>{restaurant.address}</td>
                <td>{restaurant.mobileNumber}</td>
                <td>{restaurant.city}</td>
                <td>{restaurant.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Contact;
