import React from 'react';
import UserMenuNav from './UserMenuNav';
import './UserMenuCss.css';
import Chapathiwithchicken from './Chapathiwithchicken.jpg';
import EggBiryaniFeatured from './EggBiryaniFeatured.jpg';
import Manchurian from './Manchurian.jpeg';
import chickenhyderabadibiryani from './chickenhyderabadibiryani.jpg';
import paneerbuttermasalarecipe from './paneerbuttermasalarecipe.jpg';
import vegetablemomo from './vegetablemomo.jpg';
import chickentikkamasala from './chickentikkamasala.jpg';
import eggnoodles from './eggnoodles.jpg';
import grilledchicken from './grilledchicken.jpg';
import KUTTUPORATTA from './KUTTUPORATTA.jpg';
import tamarindrice from './tamarindrice.jpeg';
import TandooriChickenRecipe from './TandooriChickenRecipe.jpg';

const staticMenus = [
  {
    itemId: 1,
    itemImage: <img src={Chapathiwithchicken} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Chapathi With Chicken Curry',
    itemCategory: 'Starters',
    itemDetails: 'Chapathi with chicken Curry In Desi Style 😍',
    itemPrice: '₹ 189.00',
  },
  {
    itemId: 2,
    itemImage: <img src={chickenhyderabadibiryani} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Hyderabadi Chicken Biryani',
    itemCategory: 'Biryani',
    itemDetails: 'Top Notch Chicken Dhum Biryani with Amazing Taste 🤩',
    itemPrice: '₹ 299.00',
  },
  {
    itemId: 3,
    itemImage: <img src={EggBiryaniFeatured} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Egg Biryani',
    itemCategory: 'Egg Biryani',
    itemDetails: 'Egg Biryani With Basmati Rice Fully Loaded With Eggs 🥚',
    itemPrice: '₹ 199.00',
  },
  {
    itemId: 4,
    itemImage: <img src={Manchurian} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Veg Manchurian',
    itemCategory: 'Fast Foods',
    itemDetails: 'Juicy Veg Manchurian which will make your mouth water 🤤',
    itemPrice: '₹ 99.00',
  },
  {
    itemId: 5,
    itemImage: <img src={paneerbuttermasalarecipe} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Panneer Butter Masala',
    itemCategory: 'Starters',
    itemDetails: 'Most Authentic Panneer Butter Masala (Mostly Ordered)',
    itemPrice: '₹ 139.00',
  },
  {
    itemId: 6,
    itemImage: <img src={vegetablemomo} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Vegetable Momo',
    itemCategory: 'Snack',
    itemDetails: 'Vegetable Momos Kolkata Style With Rich Veggies 🍅🥕',
    itemPrice: '₹ 89.00',
  },
  {
    itemId: 7,
    itemImage: <img src={chickentikkamasala} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Chicken Tikka Masala',
    itemCategory: 'Starters',
    itemDetails: 'Chicken Tikka Masala With Fine Taste Made With Guntur Mirchi 🌶',
    itemPrice: '₹ 149.00',
  },
  {
    itemId: 8,
    itemImage: <img src={eggnoodles} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Egg Noodles',
    itemCategory: 'Fast Foods',
    itemDetails: 'Authentic Noodles Delighted With Natural Spices & Egg 🥚',
    itemPrice: '₹ 119.00',
  },
  {
    itemId: 9,
    itemImage: <img src={grilledchicken} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Grilled Chicken',
    itemCategory: 'Snack',
    itemDetails: 'Fresh Chicken Layered With Home Made Masala & Grilled',
    itemPrice: '₹ 189.00',
  },
  {
    itemId: 10,
    itemImage: <img src={KUTTUPORATTA} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Kutthu Parota',
    itemCategory: 'Snack',
    itemDetails: 'Chennai Style Kutthu Parota Made in Desi Style with best taste',
    itemPrice: '₹ 129.00',
  },
  {
    itemId: 11,
    itemImage: <img src={tamarindrice} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Tamarind Rice',
    itemCategory: 'Lunch',
    itemDetails: 'Andhra Style Pulihora With Inguva as special Ingredient',
    itemPrice: '₹ 79.00',
  },
  {
    itemId: 12,
    itemImage: <img src={TandooriChickenRecipe} height="250" width="auto" alt="" style={{ height: '250px' }} />,
    itemName: 'Tandoori Chicken',
    itemCategory: 'Snack',
    itemDetails: 'Chicken Layered With Home Made Masala, Layered With Butter',
    itemPrice: '₹ 189.00',
  }
  // Add more items as needed
];

const UserMenu = () => {
  return (
    <div>
      <UserMenuNav /> <br /><br />
      <h1 style={{ textAlign: 'center' }}>Details of menus</h1>
      <div className="container">
        <div className="row">
          {staticMenus.map((menu, index) => (
            <div key={menu.itemId} className="col-md-3">
              <div className="menucard">
                <div className="card-body">
                  <h6>{menu.itemName}</h6>
                  {menu.itemImage}
                  <p>Category: {menu.itemCategory}</p>
                  <p>Details: <br /> {menu.itemDetails}</p>
                  <p>Price: {menu.itemPrice}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div style={{ textAlign: 'center', color: 'white', marginTop: '20px', marginBottom: '20px', background: 'grey' }}>
        <h5>Made with love By Lokeshwari ❤</h5>
      </div>
    </div>
  );
};

export default UserMenu;

