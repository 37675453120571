import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Navbar from '../Navbar'

const Edit = () => {
  const location = useLocation();
  const id = location.state;

  const [formData, setFormData] = useState({
    itemId: '',
    itemName: '',
    itemImage: '',
    itemCategory: '',
    itemDetails: '',
    itemPrice: '',
  });

  const [base64Image, setBase64Image] = useState(''); // Use useState to manage base64Image

  useEffect(() => {
    axios.get(`http://localhost:3030/fetchbymenuid/${id}`).then((res) => {
      setFormData(res.data);
    });
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setBase64Image(base64String); // Update base64Image using useState
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    setFormData({ ...formData, itemImage: base64Image });
    e.preventDefault();
    console.log(formData)
    axios.put(`http://localhost:3030/update/${id}`, formData).then((res) => {
      alert(res.data.message);
    });
  };

  return (
    <div>
      <Navbar />
      <form onSubmit={handleSubmit}>
        <legend>Login</legend>
        <input type="text" name="itemId" placeholder="User LoginId" value={formData.itemId} onChange={handleInputChange} /><br />
        <input type="text" name="itemName" placeholder="Employee Name" value={formData.itemName} onChange={handleInputChange} /><br />
        <input type="file" name="itemImage" accept="image/*" onChange={handleImageUpload} />
        {base64Image && <img src={base64Image} alt="Uploaded" />} <br /><br />
        <input type="text" name="itemCategory" placeholder="Gender" value={formData.itemCategory} onChange={handleInputChange} /><br />
        <input type="text" name="itemDetails" placeholder="City" value={formData.itemDetails} onChange={handleInputChange} /><br />
        <input type="text" name="itemPrice" placeholder="Email" value={formData.itemPrice} onChange={handleInputChange} /><br />
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default Edit;
