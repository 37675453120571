import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
// import App from './App'
import UserMenu from './user/UserMenu';
import AddTable from './Restaurant Pages/AddTable';
import AddMenu from './Restaurant Pages/AddMenu';
import RestaurantHome from './Restaurant Pages/RestaurantHome';
import Login from './Login';
import Register from './Register';
import Home from './Base Panel/BaseHome';
import Fetch2 from './Restaurant Pages/Fetch2';
import EditTable from './Restaurant Pages/EditTable';
// import EditMenu from './EditMenu';
import Edit from './Restaurant Pages/Edit';
import BaseHome from './Base Panel/BaseHome'
import EditMenu from './Restaurant Pages/EditMenu'
import FetchTables from './Restaurant Pages/FetchTables'
import GenerateQrCode from './Restaurant Pages/GenerateQrCode'
import MenuAndTables from './Restaurant Pages/MenuAndTables'
import Menu from './Restaurant Pages/Menu'
import FetchBooking from './Restaurant Pages/FetchBooking';
import TableBooking from './user/TableBooking';
import AlarmPanel from './Restaurant Pages/AlarmPanel'
import Contact from './user/Contact';


const router = createBrowserRouter([
    {
        path: "/",
        element: <BaseHome />,
    },
    {
        path: "Home",
        element: <Home />, 
    },
    {
        path: "/Menu",
        element: <Menu />, 
    },
    {
        path: "Fetch",
        element: <Fetch2 />, 
    },
    {
        path: "AlarmPanel",
        element: <AlarmPanel />, 
    },
    {
        path: "FetchTables",
        element: <FetchTables />, 
    },
    {
        path: "Login",
        element: <Login />, 
    },
    {
        path: "/MenuAndTables",
        element: <MenuAndTables />, 
    },
    {
        path: "Register",
        element: <Register />, 
    },
    {
        path: "/UserMenu",
        element: <UserMenu />, 
    },
    {
        path: "AddTable",
        element: <AddTable />, 
    },
    {
        path: "/RestaurantHome",
        element: <RestaurantHome />, 
    },
    {
        path: "AddMenu",
        element: <AddMenu />, 
    },
    {
        path: "TableBooking",
        element: <TableBooking />, 
    },
    {
        path: "FetchBooking",
        element: <FetchBooking />,
    },
    {
        path: "fetch/edit/:id",
        element: <EditMenu />, 
    },
    {
        path: "Edit",
        element: <Edit />, 
    },
    {
        path: "Contact",
        element: <Contact />, 
    },
    {
        path: "/BaseHome",
        element: <BaseHome />, 
    },
    {
        path: "/GenerateQrCode",
        element: <GenerateQrCode />, 
    },
    {
        path: "fetchtables/edittable/:id",
        element: <EditTable />, 
    },
  ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);


