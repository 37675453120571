import axios from 'axios'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import './UserMenuCss.css';
import { message } from 'antd';

export default class TableBooking extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       message: '',
    }
  }
  
  render() {
    return (
      <div className='form-container'>
        <fieldset className='card menu-card'>
            <legend>Table Booking Form</legend>
            <input type="date" ref="Date" placeholder="Date"/><br/>
            <input type="time" ref="Time" placeholder="Time"/><br/>
            <input type="text" ref="Name" placeholder="Name"/><br/>
            <input type="text" ref="Email" placeholder="Email"/><br/>
            <input type="text" ref="phno" placeholder="Phone No."/><br/>

            <Button color="primary" onClick={this.book}>Book</Button>
            <div class="alert alert-primary" role="alert">
              {this.state.message}
            </div>
        </fieldset>
      </div>
    )
  }
  
  book =()=>{
   const tablebooking = {
      Date: this.refs.Date.value,
      Time: this.refs.Time.value,
      Name: this.refs.Name.value,
      Email: this.refs.Email.value,
      phno: this.refs.phno.value
    }
    console.log(tablebooking)
    axios.post('http://localhost:3030/tablebooking',tablebooking).then(data=>{this.setState({message: data.data.message})})
  }
}