import axios from 'axios'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import Navbar from './Navbar'
import './RestaurantStyles.css'

export default class AddTable extends Component {
  render() {
    return (
      <div>
         <Navbar></Navbar>
        <div className="form-container">
       
        <fieldset className="card menu-card">
            <legend className='addtabletitle'>Add Table</legend>
            <input type="text" ref="TableId" placeholder="Table ID"/><br/>
            <input type="text" ref="TableName" placeholder="Table Name"/><br/>
            <input type="text" ref="TableLocation" placeholder="Table Location"/><br/>
            <Button  color="primary" onClick={this.addTable}>Add Table</Button>
        </fieldset>
      </div>

      </div>
      
    )
  }
  
  addTable =()=>{
   const tables = {
        TableId: this.refs.TableId.value,
        TableName: this.refs.TableName.value,
        TableLocation: this.refs.TableLocation.value,
    }
    console.log(tables)
    axios.post('http://localhost:3030/addTables',tables).then(data=>{console.log('Table Added Successfuly..')})
  }
}
