import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Outlet, Link } from "react-router-dom";
import Register from '../Register'
import Login from '../Login'



const Navbar = () => {
  return (
    <div>
      <nav>
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
          <div className="container-fluid">
            {/* <a className="navbar-brand" href="">Qr Menu</a> */}
            <Link to="/RestaurantHome" className='nav-link active' aria-current="page">Qr Menu</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="#" className='nav-link active' aria-current="page">Features</Link>
                </li>
                <li className="nav-item">
                  <Link to="#" className='nav-link active' aria-current="page">Product</Link>
                </li>
                <li className="nav-item">
                  <Link to="#" className='nav-link active' aria-current="page">Pricing</Link>
                </li>
                <li className="nav-item">
                </li>
              </ul>
              <div className='d-flex'>
                <Link to="/Login" className='nav-link active btn btn-primary px-3 py-2' aria-current="page">Login</Link>
                <Link to="/Register" className='nav-link active btn btn-primary px-2 py-2' aria-current="page">Register</Link>
              </div>
            </div>
          </div>
        </nav>
      </nav>
    </div>
  )
}

export default Navbar
