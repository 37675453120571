import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import '../user/UserMenuCss.css'

const Fetch2 = () => {
  const [menus, setMenus] = useState([]);
  const navigate = useNavigate();

  const Delete = (id) => {
    axios.delete(`http://localhost:3030/delete2/${id}`).then(
      (res) => console.log(res.data.message)
    );
    alert('SUCCESSFULLY DELETED');
  };

  const editMenu = (id) => {
    navigate(`/fetch/edit/${id}`, { state: id });
  };

  useEffect(() => {
    axios.get('http://localhost:3030/fetch').then((data) => {
      setMenus(data.data);
    });
  }, [navigate]);

  return (
    <div>
      <Navbar></Navbar>
      <h1>Details of menus</h1>
      <div className="container">
        <div className="row">
        {menus.map((menu) => (
          <div className="menucard col-md-3" key={menu.itemId}>
            <img src={menu.itemImage} alt={menu.itemName} />
            <div className="card-body">
              <p>Id: {menu.itemId}</p>
              <h3>{menu.itemName}</h3>
              <p>Category: {menu.itemCategory}</p>
              <p>Details: {menu.itemDetails}</p>
              <p>Price: {menu.itemPrice}</p>
              <button className="btn btn-primary" onClick={() => editMenu(menu.itemId)}>
                Edit
              </button>
              <button className="btn btn-danger" onClick={() => Delete(menu.itemId)}>
                Delete
              </button>
            </div>
          </div>
        ))}
        </div>
        
      </div>
    </div>
  );
};

export default Fetch2;
