import axios from 'axios';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Navbar from './Base Panel/Navbar';
import RegisterBackground from './RegisterBackground.jpg'
import './RegisterStyles.css'; 
import { Link, Navigate } from 'react-router-dom';

export default class Register extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       message: '',
    }
  }
  
  render() {
    if(this.state.message !== ""){
      return <Navigate to='/RestaurantHome'/>
    }
    
    return (
      <div >
        <Navbar></Navbar>
        <div className='register-container'>
          <fieldset>
            <legend>Register Form</legend>
            <input type="text" className='inputs' ref="restaurantName" placeholder="Restaurant Name" /><br />
            <input type="text" className='inputs' ref="ownerName" placeholder="Owner Name" /><br />
            <input type="text" className='inputs' ref="address" placeholder="Address" /><br />
            <input type="text" className='inputs' ref="mobileNumber" placeholder="Mobile Number" /><br />
            <input type="text" className='inputs' ref="city" placeholder="City" /><br />
            <input type="text" className='inputs' ref="email" placeholder="Email ID" /><br />
            <input type="password" className='inputs' ref="password" placeholder="Password" /><br />
            <Button color="primary" onClick={this.register}>
              Register
            </Button>
        </fieldset>
      </div>
      </div>
    );
  }

  register = () => {
    const restaurants = {
      restaurantName: this.refs.restaurantName.value,
      ownerName: this.refs.ownerName.value,
      address: this.refs.address.value,
      mobileNumber: this.refs.mobileNumber.value,
      city: this.refs.city.value,
      email: this.refs.email.value,
      password: this.refs.password.value
    };
    console.log(restaurants);
    axios.post('http://localhost:3030/register', restaurants).then((data) => {
      this.setState({message: data.data.message})
    });
  };
}